<template>
  <div>
    <div class="flex">
      <div class="rounded border">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateFrom"
              label="From"
              color="info"
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateFrom"
            color="info"
            @change="changeDate"
            @input="menu = false"
          ></v-date-picker>
        </v-menu>
      </div>
      <div class="ml-3">
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateTo"
              color="info"
              label="To"
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateTo"
            color="info"
            @change="changeDate"
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>
      </div>
      <div class="my-auto ml-3">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="info" v-bind="attrs" v-on="on" @click="refresh">
              mdi-refresh
            </v-icon>
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </div>
    </div>
    <v-simple-table class="table mt-2">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left"></th>
            <th class="text-left">Payment</th>
            <th class="text-left">Status</th>
            <th class="text-left">Payout</th>
            <th class="text-left">Invoice ID</th>
            <th class="text-left">Date</th>
            <th class="text-left">Time</th>
            <th class="text-left"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(value, index) in orders.orders" :key="index">
            <td>
              <div class="my-1">
                <p class="my-0">
                  <b>{{ value.guest.name }}</b>
                </p>
                <p class="my-0">{{ value.guest.phone }}</p>
                <p class="my-0">
                  {{ value.guest.address }} , {{ value.guest.address2 }}
                </p>
                <p v-if="value.delivery" class="my-0">
                  <span v-if="!value.delivery.tracking_no"
                    ><b
                      >Delivery planned on
                      {{ returnDay(value.delivery.createdAt) }}</b
                    ></span
                  >
                  <span v-else-if="value.delivery.tracking_no">
                    <b
                      >Delivery requested on
                      {{ returnDay(value.delivery.createdAt) }} ({{
                        value.delivery.tracking_no
                      }})</b
                    >
                  </span>
                </p>
                <p v-if="value.outlet" class="my-0">
                  <span
                    :class="'font-weight-bold text-capitalize blue-grey--text'"
                    >{{ value.outlet.name }}</span
                  >
                </p>
                <p v-if="value.delivery" class="my-0">
                  <span
                    :class="'font-weight-bold text-capitalize green--text'"
                    >{{ value.delivery.provider }}</span
                  >
                </p>
              </div>
            </td>
            <td>
              <!-- <span v-if="value.payment && value.payment.paid">Paid : RM {{ value.payment.amount }}</span> -->
              <span v-if="value.payment && value.payment.paid">
                <p style="color: green">Paid : RM {{ value.payment.amount }}</p>
                At : {{ returnDate(value.payment.paidAt) }}</span
              >
              <span v-if="value.payment && !value.payment.paid"
                ><p style="color: red">
                  Due : RM {{ value.payment.amount }}
                </p></span
              >
              <span v-if="!value.payment">CASH</span>
            </td>
            <td>
              <span v-if="value.status == 2">Pending</span>
              <span v-if="value.status == 3">Accept</span>
              <span v-if="value.status == 4">Rejected</span>
              <span v-if="value.status == 5">Paid</span>
              <span v-if="value.status == 6">On Delivery</span>
              <span v-if="value.status == 7">Delivered</span>
            </td>
            <td>
              <span v-if="value.payment && !value.payment.payout_status"
                >V1 Account</span
              >
              <span
                v-else-if="value.payment && value.payment.payout_status"
                class="{ value.payment.payout_status == 'pending' ? yellow--text : value.payment.payout_status == 'processing' ? blue--text : value.payment.payout_status == 'completed' ? green--text : red--text}"
                >{{ value.payment.payout_status }} <br />
                at {{ returnDate(value.payment.payout_at) }} <br />
                {{
                  value.payment.payout_status == ("processing" || "completed")
                    ? "ID : " + value.payment.payout_id
                    : ""
                }}
              </span>
              <span v-else>V1 Account</span>
            </td>
            <td>{{ value.invoice_id }}</td>
            <td>{{ returnDate(value.createdAt) }}</td>
            <td>{{ returnTime(value.createdAt) }}</td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="blue" v-bind="attrs" v-on="on">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="error" v-bind="attrs" v-on="on">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Remove</span>
              </v-tooltip>
              <!-- <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="green" v-bind="attrs" v-on="on">
                      <v-icon>mdi-account-arrow-left</v-icon>
                    </v-btn>
                  </template>
                  <span>Impersonate</span>
                </v-tooltip> -->
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";

export default {
  name: "ManageOrders",
  data() {
    return {
      dateFrom: new Date().toISOString().substr(0, 10),
      dateTo: new Date().toISOString().substr(0, 10),
      menu2: false,
      menu: false,
      manageOrders: {},
    };
  },

  methods: {
    getOut(i) {
      console.log(i);
    },

    returnDay(i) {
      return dayjs(i).format("DD/MM/YY h:mmA");
    },

    returnDate(i) {
      return dayjs(i).format("ddd D/M/YY");
    },

    returnTime(i) {
      return dayjs(i).format("h:mmA");
    },
    returnObj() {
      let days = {};
      // console.log(this.orders);
      this.orders.orders.forEach((o) => {
        let day = dayjs(o.createdAt).format("YYYY-MM-DD");
        days[day] = [...(days[day] || []), o];
      });
      this.orders = days;
      // console.log("days", days);
      // console.log("orders", this.orders);
      return days;
    },

    refresh() {
      this.$store.dispatch("startLoad");
      this.$store.dispatch("fetchOrders");
    },

    changeDate() {
      // console.log("from", this.dateFrom);
      // console.log("to", this.dateTo);
      this.$store.dispatch("startLoad");
      this.$store.dispatch("filterOrder", {
        from: this.dateFrom,
        to: this.dateTo,
      });
    },
  },

  computed: {
    ...mapGetters({
      orders: "getOrders",
    }),
  },

  beforeMount() {
    this.$store.dispatch("startLoad");
    this.$store.dispatch("fetchOrders");
  },

  // mounted() {
  //   let days = {};
  //   console.log(this.orders);
  //   this.orders.forEach((o) => {
  //     let day = dayjs(o.createdAt).format("YYYY-MM-DD");
  //     days[day] = [...(days[day] || []), o];
  //   });
  //   this.orders = days;
  //   console.log("orders", this.orders);
  // },
};
</script>
<style lang="scss" scoped>
.table {
  overflow-y: auto;
}
</style>
